<template>
  <div class="page-layout-wrapper">
    <div class="container">
      <PageHeader title=".Wav Pool Admin" />
      <div class="nav-items">
        <p><router-link to="/admin/songs" class="btn btn-block btn-primary">Songs</router-link></p>
        <p><router-link to="/admin/playlists" class="btn btn-block btn-primary">Playlists</router-link></p>
        <p><router-link to="/admin/users" class="btn btn-block btn-primary">Users</router-link></p>
        <p><router-link to="/admin/stats" class="btn btn-block btn-primary">Stats</router-link></p>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/Page/PageHeader'

export default {
  name: 'AdminHome',
  components: {
    PageHeader
  }
};
</script>
<style scoped>
.nav-items {
  max-width: 400px;
  margin: 0 auto;
}
</style>
